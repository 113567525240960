<template>
  <div class="game-card" @click.stop="openGame">
    <div class="card-content">
      <img :src="image" class="game-image" />
    </div>
    <div class="text-content">
      <span class="titles">{{ title }}</span>
      <div class="subtitle">{{ subtitle }}</div>
    </div>

    <span
      v-if="badge"
      class="badge"
      :style="{ backgroundColor: getColor(badge) }"
    >
      {{ badge }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'GameCard',
  props: {
    image: String,
    title: String,
    subtitle: String,
    badge: String,
    gameId: Number,
    badgeColor: {
      type: String,
      default: '#ff9800'
    }
  },
  methods: {
    getColor(input) {
      // 将输入转换为小写以进行不区分大小写的比较
      const normalizedInput = input.toLowerCase()
      // 根据输入返回相应的颜色
      switch (normalizedInput) {
        case 'new':
          return '#0078d4'
        case 'hot':
          return '#f44336'
        default: {
          // 随机返回粉色或橙色
          const colors = ['#ff4081', 'orange']
          return colors[Math.floor(Math.random() * colors.length)]
        }
      }
    },
    openGame() {
      this.$emit('openWebview', this.gameId)
    }
  }
}
</script>

<style lang="scss" scoped>
.game-card {
  display: inline-block;
  width: calc(33.33% - 10px);
  margin: 5px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.card-content {
  position: relative;
  padding-bottom: 3px;
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
}

.game-image {
  width: 100%;
  aspect-ratio: 9 / 11;
  object-fit: cover;
  border-radius: 10px;
}

.text-content {
  width: 100%;
  text-align: center;
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  left: 0%;
  bottom: 2%;
  padding: 0 3px;
  padding-bottom: 3px;
  background-color: rgb(67 62 62 / 72%);
  border-radius: 0 0 10px 10px;
}

.subtitle {
  font-size: 14px;
  line-height: 17px;
  color: rgb(230, 231, 237);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.titles {
  width: 100%;
  font-size: 0.9rem;
  color: rgb(251, 255, 47);
  transform: translate(-50%, 50%);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  text-shadow: 2px 2px 1px rgb(0, 20, 255);
}

.badge {
  position: absolute;
  top: -5px;
  right: -3px;
  padding: 2.5px 6px;
  border-radius: 12px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}
</style>
