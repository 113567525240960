<template>
  <v-app class="v-app">
    <!-- 顶部操作按钮区域 -->
    <v-container class="button-panel-container py-2 my-4 mx-3" fluid>
      <ButtonPanel :configData="configData" />
    </v-container>

    <!-- 下方左右布局 -->
    <v-container class="content-container" fluid>
      <v-row no-gutters>
        <!-- 左侧侧边栏 -->
        <v-col cols="2" class="sidebar-container">
          <Sidebar
            @menuItemSelected="handleMenuItemSelected"
            :dataOption="sideBarData"
          />
        </v-col>

        <!-- 右侧热门游戏部分 -->
        <v-col cols="10" class="main-content-container">
          <!-- 加载动画 -->
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="purple"
            class="loading-indicator mx-auto mt-18"
          >
            <span style="font-size: 10px; width: 120px"> Loading...</span>
          </v-progress-circular>

          <!-- 主内容，加载完成后才显示 -->
          <div v-else>
            <div
              v-for="(game, index) in games"
              :key="index"
              :id="game.name"
              class="cad_item mb-4"
            >
              <p
                class="head_title text-white text-left px-4 pt-5 flex justify-between"
              >
                <span>{{ game.name }}</span>
                <span class="more mr-6" @click="$router.push(`gamePanel/${index}`)"
                  >More</span
                >
              </p>
              <div class="game-grid">
                <GameCard
                  v-for="(car, ind) in game.data"
                  :key="ind"
                  :title="car.product"
                  :subtitle="car.name"
                  :image="car.pic"
                  :badge="car.badge"
                  :badgeColor="car.badgeColor"
                  :gameId="car.id"
                  @openWebview="openGameHandler"
                />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <van-popup v-model="webviewLoading" overlay lock-scroll @click.stop>
      <v-progress-circular
            v-if="webviewLoading"
            indeterminate
            color="purple"
            class="loading-indicator mx-auto mt-18"
          >
            <span style="font-size: 10px; width: 120px"> Loading...</span>
          </v-progress-circular>
    </van-popup>
    
    <!-- WebView 弹窗，包含返回按钮 -->
    <van-popup v-model="webviewVisible" overlay lock-scroll @click.stop>
      
      <div class="webview-container">
        <div class="webview-header text-left pt-4">
          <van-button
            v-if="webviewUrl"
            class="back_btn"
            @click="closeWebview"
            icon="arrow-left"
            size="small"
          ></van-button>
        </div>
        <iframe
          v-if="webviewUrl"
          :src="webviewUrl"
          class="webview-frame full-page"
        ></iframe>
      </div>
    </van-popup>
  </v-app>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import ButtonPanel from '@/components/ButtonPanel'
import GameCard from '@/components/GameCard'
import { getConfig, getGameList, openGame } from '@/api/home'

export default {
  name: 'HomeView',
  components: {
    Sidebar,
    ButtonPanel,
    GameCard
  },
  data() {
    return {
      selectedCategory: null,
      configData: JSON.parse(sessionStorage.getItem('config')) || {},
      sideBarData: JSON.parse(sessionStorage.getItem('sideBarData')) || [],
      games: JSON.parse(sessionStorage.getItem('games')) || [],
      isLoading: true, // 加载状态
      webviewLoading:false,
      webviewVisible: false, // 控制 WebView 的显示
      webviewUrl: '' // WebView 的链接
    }
  },
  created() {
    if (Object.keys(this.configData).length > 0 && this.games.length > 0) {
      // 如果 sessionStorage 中已有数据，直接使用
      this.isLoading = false
    } else if (Object.keys(this.configData).length > 0) {
      // 如果只有 configData，初始化 games 数据
      this.initializeGames()
    } else {
      // 如果没有缓存数据，则调用接口获取配置
      this.getConfigHandler()
    }
  },
  methods: {
    handleMenuItemSelected(category) {
      this.selectedCategory = category
    },
    async getGameListHandler(type) {
      const gameList = await getGameList({ game_type: type })
      return { name: type, data: gameList.data.rows }
    },
    async getConfigHandler() {
      this.isLoading = true // 开始加载
      try {
        const { data } = await getConfig()
        this.configData = data
        sessionStorage.setItem('config', JSON.stringify(data))
        await this.initializeGames()
      } catch (error) {
        console.error('加载数据出错：', error)
      } finally {
        this.isLoading = false // 加载完成
      }
    },
    async initializeGames() {
      this.isLoading = true // 开始加载游戏数据
      try {
        const gamePromises = this.configData.game_types.map((e) =>
          this.getGameListHandler(e.name)
        )
        const gameResults = await Promise.all(gamePromises)
        this.games = gameResults
        this.sideBarData = this.configData.game_types

        // 将 games 和 sideBarData 存储到 sessionStorage 中
        sessionStorage.setItem('games', JSON.stringify(gameResults))
        sessionStorage.setItem('sideBarData', JSON.stringify(this.sideBarData))
      } catch (error) {
        console.error('初始化游戏数据出错：', error)
      } finally {
        this.isLoading = false // 游戏数据加载完成
      }
    },
    async openGameHandler(id) {
      this.webviewLoading = true // 开始加载动画
      document.body.style.overflow = 'hidden' // 阻止页面滚动

      try {
        const { data } = await openGame({ id })
        if (data) {
          this.webviewUrl = data // 设置 WebView 链接
          this.webviewVisible = true // 显示 WebView
        } else {
          console.warn('无法获取到游戏链接')
        }
      } catch (error) {
        console.error('打开游戏时出错：', error)
      } finally {
        this.webviewLoading = false // 加载完成后关闭动画
        document.body.style.overflow = '' // 恢复页面滚动
      }
    },
    closeWebview() {
      this.webviewVisible = false
      this.webviewUrl = ''
      document.body.style.overflow = '' // 确保关闭 WebView 时恢复滚动
    }
  }
}
</script>

<style lang="scss" scoped>
.v-app {
  background-color: #190c33 !important;
  max-width: 400px;
  margin: auto;
}
.button-panel-container {
  width: auto;
  padding: 20px 0;
  background-color: rgba(48, 13, 97); /* 顶部按钮区域背景色 */
  border-radius: 15px;
}

.content-container {
  display: flex;
  height: calc(100vh - 221px); /* 除去顶部按钮区域的高度 */
  overflow: hidden;
  padding: 0;
  .no-gutters {
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: nowrap;
  }
}

.sidebar-container {
  max-width: 22%;
  min-width: 90px;
  height: 100%;
  padding: 20px;
  overflow-y: auto; /* 使侧边栏可以滚动 */
}

.main-content-container {
  width: calc(100% - 90px); // 保证主内容区域的宽度正确
  margin-left: auto; // 确保内容不会被侧边栏影响
  height: 100%;
  padding: 20px;
  position: sticky;
  top: 0;
  background: rgb(36 10 74); /* 主内容背景色 */
  overflow-y: auto; /* 使主内容区域可以滚动 */
  .cad_item {
   // background-color: rgba(48, 13, 97);
  }
}

.head_title {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 38px;
  display: flex;
  align-items: center;
  color: rgb(173, 180, 236);
  margin-bottom: 8px;
  padding-left: 16px;
  .more {
    font-size: 14px;
  }
}

.game-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* 让卡片之间均匀分布 */
  max-width: 100%; /* 确保容器不会超出页面宽度 */
  gap: 3px; /* 添加间距 */
  padding: 0 5px 0 10px;
}

.game-grid > * {
  flex: 1 1 calc(33% - 15px); /* 每行显示三个卡片，始终保持三个 */
  max-width: calc(33% - 15px);
  margin-bottom: 15px; /* 底部间距 */
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25%;
  height: 100%;
  background-color: transparent!important;
}

::v-deep.van-popup {
  width: 100vw !important;
  height: 100vh !important;
  background-color: transparent !important;
  z-index: 99999 !important;
  overflow: visible !important; /* 确保弹窗内容不会被截断 */
}

.webview-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.webview-header {
  position: absolute;
  flex-shrink: 0;
  padding: 10px;
  .back_btn {
    background-color: rgb(82 82 82 / 65%);
    border: 1px solid #6f6d70;
    color: #fff;
    border-radius: 50%;
    padding: 4px 8px;
  }
}

.webview-frame {
  flex-grow: 1;
  width: 100% !important;
  height: calc(100vh - 50px) !important; /* 适配 WebView header */
  border: none;
}
</style>
