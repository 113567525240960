<template>
  <v-navigation-drawer app :color="backgroundColor" dark permanent width="90">
    <v-list dense>
      <v-list-item-group>
        <v-list-item
          v-for="(item, index) in dataOption"
          :key="index"
          link
          @click="selectMenuItem(item, index)"
          :class="[{ 'is-active': activeIndex === index }]"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    dataOption: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      activeIndex: 0,
      menuItems: [],
      backgroundColor: 'transparent'
    }
  },
  methods: {
    gotoSite(id) {
      const element = document.querySelector(`#${id}`)
      const mainContentContainer = document.querySelector(
        '.main-content-container'
      )

      if (element && mainContentContainer) {
        const offset = 70
        const elementPosition = element.offsetTop
        mainContentContainer.scrollTo({
          top: elementPosition - offset,
          behavior: 'smooth'
        })
      }
    },
    selectMenuItem(item, index) {
      this.activeIndex = index
      this.gotoSite(item.name)
      this.$emit('menuItemSelected', item)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.v-navigation-drawer {
  padding: 3px 0;
  position: relative !important;
  min-width: 90px;
  z-index: inherit;
  .v-list-item {
    padding: 3px 3px;

    color: rgb(105, 109, 145) !important;
    .v-list-item__title {
      font-size: 1rem !important;
      font-weight: 550 !important;
    }
    .v-list-item__content {
      padding: 0;
    }
  }
  .v-list-item.is-active {
    color: rgba(65, 209, 244) !important;
    background-color: transparent !important;
  }
  .v-list-item--active {
    background-color: transparent !important;
  }
  .v-list-item--link:before {
    background-color: transparent !important;
  }
  .v-navigation-drawer__border {
    background-color: transparent !important;
  }
}
</style>
