<template>
  <div class="btn-page pb-3">
    <div class="person d-flex mb-1">
      <span class="account text-white"
        >ID: {{ hideAccountNumber('13555555555') }}</span
      >
      <span class="balance my-1">
        <span class="mr-2">${{ formatNumber(13.66) }}</span>
        <van-button
          size="mini"
          @click="refreshHandler"
          style="
            height: 22px;
            background-color: transparent;
            border-radius: 5px;
            border-color: #ebedf047;
          "
        >
          <span class="icon-refresh" style="font-size: 10px">Refresh</span>
        </van-button>
      </span>
    </div>
    <v-row class="text-center justify-center align-center">
      <!-- 使用 v-for 动态渲染按钮 -->
      <v-col
        v-for="(button, index) in buttonList"
        :key="index"
        cols="4"
        class="d-flex justify-center pt-2 pb-0 px-1"
      >
        <div class="icon-container mt-3">
          <v-btn
            :style="buttonStyle"
            class="icon-button"
            tile
            @click="getoPage(button)"
          >
            <!-- 根据按钮类型显示不同图标 -->
            <v-img :src="getButtonIcon(button)" />
          </v-btn>
          <!-- <span class="vip-badge" v-if="button === 'Deposit'">送VIP</span> -->
          <div class="icon-title">{{ button }}</div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'ButtonPanel',
  props: {
    configData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      buttonStyle: {
        backgroundColor: 'transparent',
        color: '#ffffff',
        width: '80px',
        height: '80px',
        boxShadow: 'none',
        borderRadius: '50%'
      },
      isRefreshing: false, // 用于显示点击状态
      // 缓存图标路径
      iconPaths: {
        'Transfer In': require('@/assets/images/icon_transfer_in.png'),
        'Transfer Out': require('@/assets/images/icon_transfer_out.png'),
        Deposit: require('@/assets/images/icon_recharge.png'),
        Withdraw: require('@/assets/images/icon_withdraw.png'),
        'Bal Conv': require('@/assets/images/icon_transfer.png')
      }
    }
  },
  computed: {
    getConfig() {
      const setData = sessionStorage.getItem('config')
        ? JSON.parse(sessionStorage.getItem('config'))
        : this.configData
      return setData
    },
    buttonList() {
      const { game_wallet_model, recharge_open } = this.getConfig
      return this.getTopButtons(game_wallet_model, recharge_open)
    }
  },
  methods: {
    getTopButtons(game_wallet_model, recharge_open) {
      const buttons = []
      if (game_wallet_model === 2) {
        buttons.push('Transfer In', 'Transfer Out') //转入转出
      }
      if (recharge_open === 1) {
        buttons.push('Deposit', 'Withdraw') //充值、提现
      }
      buttons.push('Bal Conv')
      return buttons
    },
    getButtonIcon(button) {
      return this.iconPaths[button] || ''
    },
    getoPage(name) {
      switch (name) {
        case 'Transfer In':
          return this.$router.push('transferIn')
        case 'Transfer Out':
          return this.$router.push('transferOut')
        case 'Deposit':
          return this.$router.push('deposit')
        case 'Withdraw':
          return this.$router.push('balance')
        case 'Bal Conv':
          return this.$router.push('balanceConv')
        default:
          return ''
      }
    },
    hideAccountNumber(account) {
      if (account.length < 7) {
        throw new Error('Account number is too short to mask')
      }
      const start = account.slice(0, 2)
      const end = account.slice(-3)
      return `${start}****${end}`
    },
    formatNumber(value) {
      if (value === undefined || value === null || value === '') {
        return '0.00'
      }
      return parseFloat(value).toFixed(2)
    },
    refreshHandler() {
      // 设置 isRefreshing 为 true 来显示点击效果
      this.isRefreshing = true
      console.log('refresh')

      // 例如，等待 1 秒后恢复状态
      setTimeout(() => {
        this.isRefreshing = false
      }, 800)
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-page {
  width: 90%;
  margin: 0 auto;
}

.icon-container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-button {
  .v-image {
    width: 64px;
    height: 64px;
  }
}

.icon-title {
  line-height: 20px;
  font-size: 14px;
  color: rgb(217, 221, 255);
}
.person {
  width: 100%;
  flex-wrap: nowrap;
  align-content: flex-end;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 14px;
    color: rgb(217, 221, 255);
  }
  .balance {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .van-button {
      line-height: 22px;
    }
  }
  .refresh {
    display: inline-block;
    color: rgba(22, 87, 230) !important;
    padding: 0 4px;
    border: 1px solid #ffffff2b;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  .refresh-active {
    background-color: #fff; /* 激活状态背景色 */
    color: rgba(22, 87, 230); /* 激活状态文字颜色 */
  }
}
.vip-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff4081;
  color: #ffffff;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 10px;
  font-weight: bold;
}

.v-row {
  background-color: #2c2c54; /* 确保背景颜色一致 */
  padding: 20px;
}
</style>
