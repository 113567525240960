import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/vuetify' // 这里是刚刚创建的 vuetify 配置文件
import Vant from 'vant'
import 'vant/lib/index.css'
import '@/assets/fonts/iconfont.css'
import '@/styles/main.css' // 引入 Tailwind CSS 文件

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vant)
Vue.use(Vuetify)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: (h) => h(App)
}).$mount('#app')
