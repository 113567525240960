import axiosInstance from './axiosInstance'

//获取banner列表
export const getConfig = () => {
  return axiosInstance.get('/index/h5model/config')
}

//获取游戏列表
export const getGameList = (data = null) => {
  return axiosInstance.get('/index/h5model/game_list', {
    params: data
  })
}
//登录游戏

export const openGame = (data) => {
  return axiosInstance.get('/index/h5model/open_game', {
    params: data
  })
}
//获取导航栏信息
export const getNav = () => {
  return axiosInstance.get('/api/nav')
}

//获取关于我们about列表
export const getAbout = () => {
  return axiosInstance.get('/api/about')
}

//获取特色信息
export const getFeatures = () => {
  return axiosInstance.get('/api/features')
}

//获取数字展示信息
export const getDigital = () => {
  return axiosInstance.get('/api/digital')
}

//获取行业信息
export const getIndustry = () => {
  return axiosInstance.get('/api/industry')
}

//获取合作信息
export const getCooperation = () => {
  return axiosInstance.get('/api/cooperation')
}

//获取下载信息
export const getDownload = () => {
  return axiosInstance.get('/api/download')
}
//team_profile
//获取团队信息
export const getTeamProfile = () => {
  return axiosInstance.get('/api/team_profile')
}
//获取蓝图信息
export const getBlueprint = () => {
  return axiosInstance.get('/api/blueprint')
}

//获取联系我们信息
export const getContact = () => {
  return axiosInstance.get('/api/contact')
}

//获取联系我们详情
export const getContactInfo = () => {
  return axiosInstance.get('api/contact/getContactInfo')
}

// 提交联系我们信息
export const addContact = (params) => {
  return axiosInstance.post('api/contact/create', params)
}
