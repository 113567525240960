import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  //GamePanel
  {
    path: '/gamePanel/:id',
    name: 'gamePanel',
    component: () =>
      import(/* webpackChunkName: "gamePanel" */ '@/components/GamePanel.vue')
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: () =>
      import(/* webpackChunkName: "deposit" */ '../views/DepositPage.vue')
  },
  {
    path: '/balance', //提现
    name: 'balance',
    component: () =>
      import(/* webpackChunkName: "withdraw" */ '@/components/Balance.vue')
  },
  {
    path: '/balanceInfo', //提现详情
    name: 'balanceInfo',
    component: () =>
      import(/* webpackChunkName: "withdraw" */ '@/components/BalanceInfo.vue')
  },
  {
    path: '/balanceConv', //余额转换
    name: 'balanceConv',
    component: () =>
      import(/* webpackChunkName: "withdraw" */ '@/components/BalanceConv.vue')
  },
  {
    path: '/transferIn',
    name: 'transferIn',
    component: () =>
      import(/* webpackChunkName: "transferIn" */ '@/components/TransferIn.vue')
  },
  {
    path: '/transferOut',
    name: 'transferOut',
    component: () =>
      import(
        /* webpackChunkName: "transferOut" */ '@/components/TransferOut.vue'
      )
  },
  //投注记录
  {
    path: '/betRecord',
    name: 'betRecord',
    component: () =>
      import(
        /* webpackChunkName: "betRecord" */ '@/components/BettingRecordes.vue'
      )
  },
  //资金明细
  {
    path: '/fundDetail',
    name: 'fundDetail',
    component: () =>
      import(
        /* webpackChunkName: "fundDetail" */ '@/components/FundDetails.vue'
      )
  },
  // {
  //   path: '/balance',//提现
  //   name: 'balance',
  //   component: () =>
  //     import(/* webpackChunkName: "deposit" */ '@/components/Balance.vue')
  // },
  // {
  //   path: '/bank', //绑定银行卡
  //   name: 'bankInfo',
  //   component: () =>
  //     import(/* webpackChunkName: "bank" */ '@/components/BankInfo.vue')
  // },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
