import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#ff9800',
        secondary: '#4caf50',
        accent: '#ffcc00'
      }
    }
  }
})
